import { ref, watch, provide } from "@vue/composition-api";
import actionName from "@actionName";
import store from "@/store";
import usePlan from "../plan/usePlan";
import useToastNotification from "@notification";
import { StatusNotification } from "@/constants";
// import useHandlerSidebar from "../useHandlerSidebar";

export default function useProduct() {
  const notification = useToastNotification();
  const deleteProductRequest = {
    ApplicationId: 0,
    ProductsId: []
  }
  const blankProduct = ref({
    ApplicationId: 0,
    Skus: []
  });
  const product = ref(JSON.parse(JSON.stringify(blankProduct)));

  const error = ref("");
  const products = ref([]);
  const isDataLoading = ref(true);
  const {
    currentApplicationId,
    applications
  } = usePlan();

  const columns = [
    {
      key: "Type",
      label: "Tipo",
      formatter: (value) => {
        if (value.trim() === 'SIM') return "Simple";
        if (value.trim() === 'COM') return "Compuesto";
        return value;
      },
    },
    {
      key: "Name",
      label: "Nombre",
    },
    {
      key: "Description",
      label: "Descripción",
    },
    {
      key: "BarCode",
      label: "Código de barra",
    },
    {
      key: "Price",
      label: "Precio ($)",
    },
    {
      key: "Iva",
      label: "IVA",
    },
    {
      key: "Iva",
      label: "IVA",
    },
    {
      key: "Sku",
      label: "SKU",
    },
    {
      key: "actions",
      label: "Acciones",
    },
  ];

  const {
    subscription: {
      product: { getProductsByApplication: getProductsByApplication, deleteProductsByApplication: deleteProductsByApplication }
    },
  } = actionName;
  // const { enableSidebarUpdate } = useHandlerSidebar();
  // const handleFormClick = (productData) => {
  //   enableSidebarUpdate(true);
  //   product.value = productData;
  // };

  const _getProductsByApplication = async (applicationId) => {
    try {
      const data = await store.dispatch(getProductsByApplication, applicationId);
      products.value = data.map(item => JSON.parse(item.metadata));
    } catch (err) {
      error.value = err.message;
    } finally {
      isDataLoading.value = false;
    }
  }

  const deleteProduct = async (productData) => {
    deleteProductRequest.ApplicationId = currentApplicationId.value;
    deleteProductRequest.ProductsId.push(productData.Id);
    try {
      // loading.value = true;
      await store.dispatch(deleteProductsByApplication, deleteProductRequest);
      notification.dispatchNotification(
        "Productos",
        StatusNotification.SUCCESS,
        "Producto eliminado"
      );
    } catch (error) {
      notification.dispatchNotification(
        "Productos",
        StatusNotification.ERROR,
        "No se pudo dar de baja el producto"
      );
    } finally {
      // loading.value = false;
      // fileSelected.value = null;
    }
  }

  const onSearch = (query) => {
    console.log("Buscando -> " + query);
  };
  provide("onSearch", onSearch);

  watch(currentApplicationId, async (val) => {
    if (Number.isInteger(val) && val > 0) {
      _getProductsByApplication(val);
    }
  });

  return {
    product,
    columns,
    currentApplicationId,
    applications,
    products,
    isDataLoading,
    error,
    // handleFormClick,
    deleteProduct,
  };
}

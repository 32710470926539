<template>
  <b-container>
    <b-form-group label="Aplicaciones">
      <b-form-select v-model="currentApplicationId" :options="applications" />
    </b-form-group>
    <container
      :error="error"
      title="Mantenimiento de productos"
      titleCreateButton="Nuevo Producto"
    >
      <template #body>
        <b-table
          :fields="columns"
          :items="products"
          :busy="isDataLoading"
          responsive
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(actions)="row">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer mr-1"
              size="20"
              @click="deleteProduct(row.item)"
            />
          </template>
        </b-table>
      </template>
      <template #sidebar>
        <create-product />
      </template>
    </container>
  </b-container>
</template>
<script>
import Container from "../Container.vue";
import useProduct from "./useProduct";
import CreateProduct from "./Create.vue";
import Product from "./Product.vue";
import { provide } from "@vue/composition-api";
import {
  BCard,
  BCardText,
  BCardGroup,
  BCardBody,
  BContainer,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BTable,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    BCardGroup,
    BCardBody,
    BContainer,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BTable,
    Container,
    CreateProduct,
    Product,
  },
  setup() {
    const {
      product,
      columns,
      currentApplicationId,
      applications,
      products,
      isDataLoading,
      error,
      // handleFormClick,
      deleteProduct,
    } = useProduct();
    provide("currentApplicationId", currentApplicationId);
    return {
      product,
      columns,
      currentApplicationId,
      applications,
      products,
      isDataLoading,
      error,
      // handleFormClick,
      deleteProduct,
    };
  },
};
</script>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "sidebar-class": "sidebar-lg",
      "visible": _vm.$store.state['formSidebar'].componentSidebarCreate,
      "bg-variant": "white",
      "shadow": "",
      "backdrop": "",
      "no-header": "",
      "right": ""
    },
    on: {
      "change": _vm.enableSidebarCreate
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v("Carga de productos")]), _c('div', [_c('feather-icon', {
          staticClass: "ml-1 cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "16"
          },
          on: {
            "click": hide
          }
        })], 1)]), _c('b-form', {
          staticClass: "p-2"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm.uploadingFile ? _c('p', [_vm._v("Subiendo archivo...")]) : _c('b-form-group', {
          attrs: {
            "label": "Subir archivo",
            "label-for": "vi-file"
          }
        }, [_c('b-form-file', {
          attrs: {
            "id": "vi-file",
            "accept": _vm.fileType(),
            "placeholder": ""
          },
          model: {
            value: _vm.fileSelected,
            callback: function callback($$v) {
              _vm.fileSelected = $$v;
            },
            expression: "fileSelected"
          }
        })], 1)], 1), _vm.uploadingFile ? _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-progress', {
          staticClass: "progress-bar-primary",
          attrs: {
            "value": _vm.progress,
            "variant": "primary",
            "animated": ""
          }
        })], 1) : _vm._e(), _c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "block": "",
            "variant": "primary",
            "disabled": _vm.loading || _vm.uploadingFile
          },
          on: {
            "click": _vm.uploadFile
          }
        }, [_vm.loading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "label": "Loading..."
          }
        }) : _c('div', [_vm._v("Subir archivo")])], 1)], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
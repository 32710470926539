<template>
  <b-sidebar
    sidebar-class="sidebar-lg"
    :visible="$store.state['formSidebar'].componentSidebarCreate"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="enableSidebarCreate"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Carga de productos</h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <b-form class="p-2">
        <b-row>
          <b-col cols="12">
            <p v-if="uploadingFile">Subiendo archivo...</p>
            <b-form-group v-else label="Subir archivo" label-for="vi-file">
              <b-form-file
                id="vi-file"
                v-model="fileSelected"
                :accept="fileType()"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col v-if="uploadingFile" cols="12">
            <b-progress
              :value="progress"
              class="progress-bar-primary"
              variant="primary"
              animated
            />
          </b-col>
          <b-col cols="12" class="mt-2">
            <b-button
              block
              class="mr-1"
              @click="uploadFile"
              variant="primary"
              :disabled="loading || uploadingFile"
            >
              <b-spinner v-if="loading" small label="Loading..." />
              <div v-else>Subir archivo</div>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-sidebar>
</template>
<script>
import Container from "../FormContainer";
import useCreateProduct from "./useCreateProduct";
import vSelect from "vue-select";
import useHandlerSidebar from "../useHandlerSidebar";
import {
  BProgress,
  BFormFile,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BSidebar,
} from "bootstrap-vue";
export default {
  components: {
    BProgress,
    BFormFile,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BSidebar,
    Container,
    vSelect,
  },
  setup() {
    const {
      uploadingFile,
      fileType,
      fileSelected,
      progress,
      loading,
      uploadFile,
    } = useCreateProduct();
    const { enableSidebarCreate } = useHandlerSidebar();
    return {
      uploadingFile,
      fileType,
      fileSelected,
      progress,
      loading,
      uploadFile,
      enableSidebarCreate,
    };
  },
};
</script>

import { ref, inject } from "@vue/composition-api";
import store from "@/store";
import actionName from "@actionName";
import useToastNotification from "@notification";
import { StatusNotification } from "@/constants";
import * as XLSX from 'xlsx';

export default function useCreateProduct() {
	const product = {
		ApplicationId: 0,
		Skus: []
	}
	const loading = ref(false);
	const uploadingFile = ref(false);
	const fileSelected = ref(null);
	const progress = ref(0);
	const notification = useToastNotification();
	const currentApplicationId = inject('currentApplicationId');

	const {
		subscription: {
			product: { storeProducts: storeProductsByApplication },
		},
	} = actionName;

	const createProduct = async (data) => {
		try {
			loading.value = true;
			await store.dispatch(storeProductsByApplication, data);
			notification.dispatchNotification(
				"Productos",
				StatusNotification.SUCCESS,
				"Productos registrados"
			);
		} catch (error) {
			notification.dispatchNotification(
				"Productos",
				StatusNotification.ERROR,
				"No se pudo completar el registro"
			);
		} finally {
			loading.value = false;
			fileSelected.value = null;
		}
	};
	const isValidData = (arrayData) => {
		if (!Array.isArray(arrayData)) return false;
		if (arrayData.length === 0) return false;
		return true;
	}

	const fileType = () => ".xlsx, .xls"

	const uploadFile = () => {
		if (fileSelected.value === null || fileSelected.value === undefined) {
			notification.dispatchNotification(
				"Productos",
				StatusNotification.ERROR,
				"No se pudo completar el registro, necesita subir un archivo excel con los SKU"
			);
			return;
		}
		const fileReader = new FileReader();
		fileReader.onload = ev => {
			const data = ev.target.result;
			const workbook = XLSX.read(data, {
				type: "binary"
			});
			const wsname = workbook.SheetNames[0];
			const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
			if (!isValidData(ws)) {
				notification.dispatchNotification(
					"Productos",
					StatusNotification.ERROR,
					"Archivo invalido o está vacío"
				);
				return;
			}
			for (var i = 0; i < ws.length; i++) {
				if (ws[i].sku) product.Skus.push(ws[i].sku);
			}
			product.ApplicationId = currentApplicationId.value;
			createProduct(product);
		};
		fileReader.readAsBinaryString(fileSelected.value);
	};

	return {
		uploadingFile,
		fileType,
		fileSelected,
		progress,
		loading,
		uploadFile,
	};
}

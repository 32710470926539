var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-list-group', [_c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("Tipo: " + _vm._s(_vm.product ? _vm.product.Type : ""))]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("Nombre: " + _vm._s(_vm.product ? _vm.product.Name : ""))]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("Descripción: " + _vm._s(_vm.product ? _vm.product.Description : ""))]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("Còdigo de Barra: " + _vm._s(_vm.product ? _vm.product.BarCode : ""))]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("Stock: " + _vm._s(_vm.product ? _vm.product.Stock : ""))]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("Precio: " + _vm._s(_vm.product ? _vm.product.Price : ""))]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("IVA: " + _vm._s(_vm.product ? _vm.product.Iva : ""))]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("Estado: " + _vm._s(_vm.product ? _vm.product.Status : ""))]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v("SKU: " + _vm._s(_vm.product ? _vm.product.Sku : ""))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
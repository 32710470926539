<template>
  <b-list-group>
    <b-list-group-item button>Tipo: {{ product ? product.Type : "" }}</b-list-group-item>
    <b-list-group-item button>Nombre: {{ product ? product.Name : "" }}</b-list-group-item>
    <b-list-group-item button>Descripción: {{ product ? product.Description : "" }}</b-list-group-item>
    <b-list-group-item button>Còdigo de Barra: {{ product ? product.BarCode : "" }}</b-list-group-item>
    <b-list-group-item button>Stock: {{ product ? product.Stock : "" }}</b-list-group-item>
    <b-list-group-item button>Precio: {{ product ? product.Price : "" }}</b-list-group-item>
    <b-list-group-item button>IVA: {{ product ? product.Iva : "" }}</b-list-group-item>
    <b-list-group-item button>Estado: {{ product ? product.Status : "" }}</b-list-group-item>
    <b-list-group-item button>SKU: {{ product ? product.Sku : "" }}</b-list-group-item>
  </b-list-group>
</template>
<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";
export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
};
</script>